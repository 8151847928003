@font-face {
    font-family: "LfIcons";
    src: url("./LfIcons.ttf?61330fc1c0697699c2e3e3c9bab67843") format("truetype"),
url("./LfIcons.woff?61330fc1c0697699c2e3e3c9bab67843") format("woff"),
url("./LfIcons.woff2?61330fc1c0697699c2e3e3c9bab67843") format("woff2");
}

.lf-icon:before {
    font-family: LfIcons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lf-icon.lf-icon-arrow_counter_clockwise_bold:before {
    content: "\f101";
}
.lf-icon.lf-icon-arrow_left:before {
    content: "\f102";
}
.lf-icon.lf-icon-arrow_right:before {
    content: "\f103";
}
.lf-icon.lf-icon-arrow_up_bold:before {
    content: "\f104";
}
.lf-icon.lf-icon-arrows_parallel_horizontal:before {
    content: "\f105";
}
.lf-icon.lf-icon-asterisk:before {
    content: "\f106";
}
.lf-icon.lf-icon-attach:before {
    content: "\f107";
}
.lf-icon.lf-icon-block:before {
    content: "\f108";
}
.lf-icon.lf-icon-calendar:before {
    content: "\f109";
}
.lf-icon.lf-icon-caret_down_small_filled:before {
    content: "\f10a";
}
.lf-icon.lf-icon-caret_down_small:before {
    content: "\f10b";
}
.lf-icon.lf-icon-caret_down:before {
    content: "\f10c";
}
.lf-icon.lf-icon-caret_left_double:before {
    content: "\f10d";
}
.lf-icon.lf-icon-caret_left:before {
    content: "\f10e";
}
.lf-icon.lf-icon-caret_right_double:before {
    content: "\f10f";
}
.lf-icon.lf-icon-caret_right:before {
    content: "\f110";
}
.lf-icon.lf-icon-caret_up_small:before {
    content: "\f111";
}
.lf-icon.lf-icon-caret_up:before {
    content: "\f112";
}
.lf-icon.lf-icon-chart:before {
    content: "\f113";
}
.lf-icon.lf-icon-check-circle:before {
    content: "\f114";
}
.lf-icon.lf-icon-check_bold:before {
    content: "\f115";
}
.lf-icon.lf-icon-check:before {
    content: "\f116";
}
.lf-icon.lf-icon-clock:before {
    content: "\f117";
}
.lf-icon.lf-icon-clockwise:before {
    content: "\f118";
}
.lf-icon.lf-icon-close_bold:before {
    content: "\f119";
}
.lf-icon.lf-icon-close:before {
    content: "\f11a";
}
.lf-icon.lf-icon-cloud_upload:before {
    content: "\f11b";
}
.lf-icon.lf-icon-copy:before {
    content: "\f11c";
}
.lf-icon.lf-icon-delete:before {
    content: "\f11d";
}
.lf-icon.lf-icon-desktop:before {
    content: "\f11e";
}
.lf-icon.lf-icon-download:before {
    content: "\f11f";
}
.lf-icon.lf-icon-edit:before {
    content: "\f120";
}
.lf-icon.lf-icon-email_send:before {
    content: "\f121";
}
.lf-icon.lf-icon-file_check:before {
    content: "\f122";
}
.lf-icon.lf-icon-file_error:before {
    content: "\f123";
}
.lf-icon.lf-icon-file:before {
    content: "\f124";
}
.lf-icon.lf-icon-filter:before {
    content: "\f125";
}
.lf-icon.lf-icon-flag_filled:before {
    content: "\f126";
}
.lf-icon.lf-icon-flag:before {
    content: "\f127";
}
.lf-icon.lf-icon-globe:before {
    content: "\f128";
}
.lf-icon.lf-icon-info_bold:before {
    content: "\f129";
}
.lf-icon.lf-icon-link_break:before {
    content: "\f12a";
}
.lf-icon.lf-icon-link:before {
    content: "\f12b";
}
.lf-icon.lf-icon-log_out:before {
    content: "\f12c";
}
.lf-icon.lf-icon-minus_bold:before {
    content: "\f12d";
}
.lf-icon.lf-icon-minus_circle:before {
    content: "\f12e";
}
.lf-icon.lf-icon-minus:before {
    content: "\f12f";
}
.lf-icon.lf-icon-mobile:before {
    content: "\f130";
}
.lf-icon.lf-icon-more_vertical:before {
    content: "\f131";
}
.lf-icon.lf-icon-password_reset:before {
    content: "\f132";
}
.lf-icon.lf-icon-plus_bold:before {
    content: "\f133";
}
.lf-icon.lf-icon-plus:before {
    content: "\f134";
}
.lf-icon.lf-icon-question_circle:before {
    content: "\f135";
}
.lf-icon.lf-icon-repeat:before {
    content: "\f136";
}
.lf-icon.lf-icon-scanner_barcode:before {
    content: "\f137";
}
.lf-icon.lf-icon-search:before {
    content: "\f138";
}
.lf-icon.lf-icon-settings:before {
    content: "\f139";
}
.lf-icon.lf-icon-siren:before {
    content: "\f13a";
}
.lf-icon.lf-icon-store:before {
    content: "\f13b";
}
.lf-icon.lf-icon-subitems:before {
    content: "\f13c";
}
.lf-icon.lf-icon-task_square:before {
    content: "\f13d";
}
.lf-icon.lf-icon-unlock:before {
    content: "\f13e";
}
.lf-icon.lf-icon-user_add:before {
    content: "\f13f";
}
.lf-icon.lf-icon-user:before {
    content: "\f140";
}
.lf-icon.lf-icon-visible_off:before {
    content: "\f141";
}
.lf-icon.lf-icon-visible:before {
    content: "\f142";
}
.lf-icon.lf-icon-warning_circle:before {
    content: "\f143";
}
.lf-icon.lf-icon-warning_triangle_filled:before {
    content: "\f144";
}
