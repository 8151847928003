@mixin for-root () {
  :root {
    --lf-dropdown-border-radius: 6px;
    --lf-dropdown-border-width: 2px;

    --lf-dropdown-default-max-height: 500px;

    --lf-dropdown-header-padding-horizontal: 16px;
    --lf-dropdown-header-padding-vertical: 16px;

    --lf-dropdown-body-padding-horizontal: 16px;
    --lf-dropdown-body-padding-vertical: 8px;

    --lf-dropdown-footer-padding-horizontal: 16px;
    --lf-dropdown-footer-padding-vertical: 12px;
  }

  .lf-dropdown {
    box-shadow: var(--lf-elevation-300);
  }
  .lf-overlay-above .lf-dropdown {
    box-shadow: var(--lf-elevation-300-inverse);
  }
}
