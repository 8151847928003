@mixin for-root {
  .lf-cursor-default {
    cursor: default !important;
  }
  .lf-cursor-pointer {
    cursor: pointer !important;
  }
  .lf-cursor-not-allowed {
    cursor: not-allowed !important;
  }
  .lf-cursor-help {
    cursor: help !important;
  }
  .lf-cursor-context-menu {
    cursor: context-menu !important;
  }
  .lf-cursor-wait {
    cursor: wait !important;
  }
  .lf-cursor-progress {
    cursor: progress !important;
  }
}
