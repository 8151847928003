@mixin for-root {
  .lf-svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;

    svg {
      display: block;
      color: inherit;
    }
  }

  .icon-color .lf-svg-icon svg * {
    stroke: currentColor;
  }
}
