@mixin for-root {
  :root {
    --lf-radio-s-size: 16px;
    --lf-radio-s-indicator-size: 6px;
    --lf-radio-s-gap: 10px;
    --lf-radio-s-label-font: var(--lf-subtitle-s-font);
    --lf-radio-s-group-vertical-gap: 10px;
    --lf-radio-s-group-horizontal-gap: 20px;
    --lf-radio-s-area-vertical-offset: -4px;
    --lf-radio-s-area-horizontal-offset: -8px;

    --lf-radio-m-size: 20px;
    --lf-radio-m-indicator-size: 8px;
    --lf-radio-m-gap: 12px;
    --lf-radio-m-label-font: var(--lf-subtitle-m-font);
    --lf-radio-m-group-vertical-gap: 12px;
    --lf-radio-m-group-horizontal-gap: 24px;
    --lf-radio-m-area-vertical-offset: -5px;
    --lf-radio-m-area-horizontal-offset: -8px;

    --lf-radio-l-size: 24px;
    --lf-radio-l-indicator-size: 10px;
    --lf-radio-l-gap: 12px;
    --lf-radio-l-label-font: var(--lf-subtitle-font);
    --lf-radio-l-group-vertical-gap: 12px;
    --lf-radio-l-group-horizontal-gap: 24px;
    --lf-radio-l-area-vertical-offset: -5px;
    --lf-radio-l-area-horizontal-offset: -8px;
  }

  .lf-xl, .lf-l {
    --lf-radio-size: var(--lf-radio-l-size);
    --lf-radio-indicator-size: var(--lf-radio-l-indicator-size);
    --lf-radio-gap: var(--lf-radio-l-gap);
    --lf-radio-label-font: var(--lf-radio-l-label-font);
    --lf-radio-group-vertical-gap: var(--lf-radio-l-group-vertical-gap);
    --lf-radio-group-horizontal-gap: var(--lf-radio-l-group-horizontal-gap);
    --lf-radio-area-vertical-offset: var(--lf-radio-l-area-vertical-offset);
    --lf-radio-area-horizontal-offset: var(--lf-radio-l-area-horizontal-offset);
  }

  // default radio size is m
  .lf-m, :root {
    --lf-radio-size: var(--lf-radio-m-size);
    --lf-radio-indicator-size: var(--lf-radio-m-indicator-size);
    --lf-radio-gap: var(--lf-radio-m-gap);
    --lf-radio-label-font: var(--lf-radio-m-label-font);
    --lf-radio-group-vertical-gap: var(--lf-radio-m-group-vertical-gap);
    --lf-radio-group-horizontal-gap: var(--lf-radio-m-group-horizontal-gap);
    --lf-radio-area-vertical-offset: var(--lf-radio-m-area-vertical-offset);
    --lf-radio-area-horizontal-offset: var(--lf-radio-m-area-horizontal-offset);
  }

  .lf-s, .lf-xs {
    --lf-radio-size: var(--lf-radio-s-size);
    --lf-radio-indicator-size: var(--lf-radio-s-indicator-size);
    --lf-radio-gap: var(--lf-radio-s-gap);
    --lf-radio-label-font: var(--lf-radio-s-label-font);
    --lf-radio-group-vertical-gap: var(--lf-radio-s-group-vertical-gap);
    --lf-radio-group-horizontal-gap: var(--lf-radio-s-group-horizontal-gap);
    --lf-radio-area-vertical-offset: var(--lf-radio-s-area-vertical-offset);
    --lf-radio-area-horizontal-offset: var(--lf-radio-s-area-horizontal-offset);
  }
}
