@mixin for-root {
  :root {
    --lf-checkbox-s-size: 16px;
    --lf-checkbox-s-border-radius: 4px;
    --lf-checkbox-s-indicator-icon-size: 11px;
    --lf-checkbox-s-label-font: var(--lf-subtitle-s-font);
    --lf-checkbox-s-gap: 10px;
    --lf-checkbox-s-group-vertical-gap: 10px;
    --lf-checkbox-s-group-horizontal-gap: 20px;
    --lf-checkbox-s-area-vertical-offset: -4px;
    --lf-checkbox-s-area-horizontal-offset: -8px;

    --lf-checkbox-m-size: 18px;
    --lf-checkbox-m-border-radius: 4px;
    --lf-checkbox-m-indicator-icon-size: 12px;
    --lf-checkbox-m-label-font: var(--lf-subtitle-m-font);
    --lf-checkbox-m-gap: 12px;
    --lf-checkbox-m-group-vertical-gap: 12px;
    --lf-checkbox-m-group-horizontal-gap: 24px;
    --lf-checkbox-m-area-vertical-offset: -5px;
    --lf-checkbox-m-area-horizontal-offset: -8px;

    --lf-checkbox-l-size: 20px;
    --lf-checkbox-l-border-radius: 4px;
    --lf-checkbox-l-indicator-icon-size: 13px;
    --lf-checkbox-l-label-font: var(--lf-subtitle-font);
    --lf-checkbox-l-gap: 12px;
    --lf-checkbox-l-group-vertical-gap: 12px;
    --lf-checkbox-l-group-horizontal-gap: 24px;
    --lf-checkbox-l-area-vertical-offset: -5px;
    --lf-checkbox-l-area-horizontal-offset: -8px;
  }

  .lf-xl, .lf-l {
    --lf-checkbox-size: var(--lf-checkbox-l-size);
    --lf-checkbox-border-radius: var(--lf-checkbox-l-border-radius);
    --lf-checkbox-indicator-icon-size: var(--lf-checkbox-l-indicator-icon-size);
    --lf-checkbox-label-font: var(--lf-checkbox-l-label-font);
    --lf-checkbox-gap: var(--lf-checkbox-l-gap);
    --lf-checkbox-group-vertical-gap: var(--lf-checkbox-l-group-vertical-gap);
    --lf-checkbox-group-horizontal-gap: var(--lf-checkbox-l-group-horizontal-gap);
    --lf-checkbox-area-vertical-offset: var(--lf-checkbox-l-area-vertical-offset);
    --lf-checkbox-area-horizontal-offset: var(--lf-checkbox-l-area-horizontal-offset);
  }

  // default checkbox size is m
  .lf-m, :root {
    --lf-checkbox-size: var(--lf-checkbox-m-size);
    --lf-checkbox-border-radius: var(--lf-checkbox-m-border-radius);
    --lf-checkbox-indicator-icon-size: var(--lf-checkbox-m-indicator-icon-size);
    --lf-checkbox-label-font: var(--lf-checkbox-m-label-font);
    --lf-checkbox-gap: var(--lf-checkbox-m-gap);
    --lf-checkbox-group-vertical-gap: var(--lf-checkbox-m-group-vertical-gap);
    --lf-checkbox-group-horizontal-gap: var(--lf-checkbox-m-group-horizontal-gap);
    --lf-checkbox-area-vertical-offset: var(--lf-checkbox-m-area-vertical-offset);
    --lf-checkbox-area-horizontal-offset: var(--lf-checkbox-m-area-horizontal-offset);
  }

  .lf-s, .lf-xs {
    --lf-checkbox-size: var(--lf-checkbox-s-size);
    --lf-checkbox-border-radius: var(--lf-checkbox-s-border-radius);
    --lf-checkbox-indicator-icon-size: var(--lf-checkbox-s-indicator-icon-size);
    --lf-checkbox-label-font: var(--lf-checkbox-s-label-font);
    --lf-checkbox-gap: var(--lf-checkbox-s-gap);
    --lf-checkbox-group-vertical-gap: var(--lf-checkbox-s-group-vertical-gap);
    --lf-checkbox-group-horizontal-gap: var(--lf-checkbox-s-group-horizontal-gap);
    --lf-checkbox-area-vertical-offset: var(--lf-checkbox-s-area-vertical-offset);
    --lf-checkbox-area-horizontal-offset: var(--lf-checkbox-s-area-horizontal-offset);
  }
}
