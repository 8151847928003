@mixin for-root {
  :root {
    --lf-paginator-info-gap: 16px;
    --lf-paginator-info-font: var(--lf-caption-s-font);

    --lf-paginator-buttons-gap: 2px;
    --lf-paginator-button-size: 26px;
    --lf-paginator-button-padding: 3px;
    --lf-paginator-button-icon-size: 12px;
    --lf-paginator-button-border-size: 1px;
    --lf-paginator-button-border-radius: 4px;
    --lf-paginator-button-font: var(--lf-caption-s-font);
  }
}
