@mixin for-root () {
  :root {
    --lf-dialog-border-radius: 12px;
    --lf-dialog-border-width: 1px;

    --lf-dialog-header-padding-vertical: 16px;
    --lf-dialog-header-padding-horizontal: 24px;
    --lf-dialog-header-gap: 16px;
    --lf-dialog-header-font: var(--lf-h5-font);
    --lf-dialog-header-close-btn-size: 32px;
    --lf-dialog-header-close-btn-icon-size: 22px;
    --lf-dialog-header-icon-wrapper-size: 40px;
    --lf-dialog-header-icon-size: 24px;

    --lf-dialog-content-padding-vertical: 32px;
    --lf-dialog-content-padding-horizontal: 24px;

    --lf-dialog-footer-padding-vertical: 14px;
    --lf-dialog-footer-padding-horizontal: 24px;
    --lf-dialog-footer-gap: 8px;
  }
}
