@mixin for-root () {
  :root {
    --lf-side-overlay-close-btn-size: 24px;

    --lf-side-overlay-border-width: 1px;

    --lf-side-overlay-header-padding-horizontal: 16px;
    --lf-side-overlay-header-padding-vertical: 16px;

    --lf-side-overlay-body-padding-horizontal: 16px;
    --lf-side-overlay-body-padding-vertical: 16px;

    --lf-side-overlay-footer-padding-horizontal: 16px;
    --lf-side-overlay-footer-padding-vertical: 16px;
  }
}
