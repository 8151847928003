// helpers for margins/paddings
@mixin for-root {
  .lf-margin_a {
    margin: auto !important;
  }
  .lf-margin-l_a {
    margin-left: auto !important;
  }
  .lf-margin-r_a {
    margin-right: auto !important;
  }
  .lf-margin-t_a {
    margin-top: auto !important;
  }
  .lf-margin-b_a {
    margin-bottom: auto !important;
  }
}
