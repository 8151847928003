@mixin for-root {
  :root {
    --lf-scrollbar-webkit-size: 4px;
    --lf-scrollbar-webkit-border-radius: 2px;

    --lf-scrollbar-ff-width: thin;

    --lf-scrollbar-width: 0px;
    // This is default value. Real value will be set by code.
    // There will be set the actual width of scrollbar.
    // You can use it for calculating sizes for elements, eg.:
    // padding-right: calc(10px - var(--lf-scrollbar-width));
  }
  // Style scrollbars only if they are displayed by OS/device/browser
  .lf-device_with-scrollbars.lf-scrollbars_auto, .lf-scrollbars_enable {
    // styles for FF
    * {
      scrollbar-width: var(--lf-scrollbar-ff-width);
      scrollbar-color: var(--lf-main-700) var(--lf-main-300);
    }

    // styles for webkit browsers
    *::-webkit-scrollbar {
      width: var(--lf-scrollbar-webkit-size);
      height: var(--lf-scrollbar-webkit-size);
      background: var(--lf-main-300);
    }
    *::-webkit-scrollbar-thumb {
      border-radius: var(--lf-scrollbar-webkit-border-radius);
      background: var(--lf-main-700);
      &:hover {
        background: var(--lf-main-900);
      }
    }
    *::-webkit-scrollbar-track-piece {
      background: var(--lf-main-300);
    }
    *::-webkit-scrollbar-track {
      border-radius: var(--lf-scrollbar-webkit-border-radius);
    }
    *::-webkit-scrollbar-corner {
      background: var(--lf-main-300);
    }
  }
}
