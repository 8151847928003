@mixin for-root {
  :root {
    --lf-btn-xl-height: 50px;
    --lf-btn-xl-min-width: 150px;
    --lf-btn-xl-vertical-padding: 13px;
    --lf-btn-xl-horizontal-padding: 24px;
    --lf-btn-xl-gap: 8px;
    --lf-btn-xl-border-radius: 8px;
    --lf-btn-xl-icon-size: 24px;
    --lf-btn-xl-font: var(--lf-subtitle-l-font);

    --lf-btn-l-height: 44px;
    --lf-btn-l-min-width: 144px;
    --lf-btn-l-vertical-padding: 11px;
    --lf-btn-l-horizontal-padding: 20px;
    --lf-btn-l-gap: 8px;
    --lf-btn-l-border-radius: 8px;
    --lf-btn-l-icon-size: 22px;
    --lf-btn-l-font: var(--lf-subtitle-font);

    --lf-btn-m-height: 38px;
    --lf-btn-m-min-width: 138px;
    --lf-btn-m-vertical-padding: 9px;
    --lf-btn-m-horizontal-padding: 20px;
    --lf-btn-m-gap: 6px;
    --lf-btn-m-border-radius: 6px;
    --lf-btn-m-icon-size: 20px;
    --lf-btn-m-font: var(--lf-subtitle-m-font);

    --lf-btn-s-height: 32px;
    --lf-btn-s-min-width: 132px;
    --lf-btn-s-vertical-padding: 7px;
    --lf-btn-s-horizontal-padding: 18px;
    --lf-btn-s-gap: 6px;
    --lf-btn-s-border-radius: 6px;
    --lf-btn-s-icon-size: 18px;
    --lf-btn-s-font: var(--lf-subtitle-s-font);

    --lf-btn-xs-height: 26px;
    --lf-btn-xs-min-width: 126px;
    --lf-btn-xs-vertical-padding: 5px;
    --lf-btn-xs-horizontal-padding: 16px;
    --lf-btn-xs-gap: 4px;
    --lf-btn-xs-border-radius: 6px;
    --lf-btn-xs-icon-size: 16px;
    --lf-btn-xs-font: var(--lf-subtitle-s-font);

    --lf-btn-group-gap: 12px;
  }

  .lf-xl {
    --lf-btn-height: var(--lf-btn-xl-height);
    --lf-btn-min-width: var(--lf-btn-xl-min-width);
    --lf-btn-vertical-padding: var(--lf-btn-xl-vertical-padding);
    --lf-btn-horizontal-padding: var(--lf-btn-xl-horizontal-padding);
    --lf-btn-gap: var(--lf-btn-xl-gap);
    --lf-btn-border-radius: var(--lf-btn-xl-border-radius);
    --lf-btn-icon-size: var(--lf-btn-xl-icon-size);
    --lf-btn-font: var(--lf-btn-xl-font);
  }
  .lf-l {
    --lf-btn-height: var(--lf-btn-l-height);
    --lf-btn-min-width: var(--lf-btn-l-min-width);
    --lf-btn-vertical-padding: var(--lf-btn-l-vertical-padding);
    --lf-btn-horizontal-padding: var(--lf-btn-l-horizontal-padding);
    --lf-btn-gap: var(--lf-btn-l-gap);
    --lf-btn-border-radius: var(--lf-btn-l-border-radius);
    --lf-btn-icon-size: var(--lf-btn-l-icon-size);
    --lf-btn-font: var(--lf-btn-l-font);
  }
  // default btn size is m
  :root, .lf-m {
    --lf-btn-height: var(--lf-btn-m-height);
    --lf-btn-min-width: var(--lf-btn-m-min-width);
    --lf-btn-vertical-padding: var(--lf-btn-m-vertical-padding);
    --lf-btn-horizontal-padding: var(--lf-btn-m-horizontal-padding);
    --lf-btn-gap: var(--lf-btn-m-gap);
    --lf-btn-border-radius: var(--lf-btn-m-border-radius);
    --lf-btn-icon-size: var(--lf-btn-m-icon-size);
    --lf-btn-font: var(--lf-btn-m-font);
  }
  .lf-s {
    --lf-btn-height: var(--lf-btn-s-height);
    --lf-btn-min-width: var(--lf-btn-s-min-width);
    --lf-btn-vertical-padding: var(--lf-btn-s-vertical-padding);
    --lf-btn-horizontal-padding: var(--lf-btn-s-horizontal-padding);
    --lf-btn-gap: var(--lf-btn-s-gap);
    --lf-btn-border-radius: var(--lf-btn-s-border-radius);
    --lf-btn-icon-size: var(--lf-btn-s-icon-size);
    --lf-btn-font: var(--lf-btn-s-font);
  }
  .lf-xs {
    --lf-btn-height: var(--lf-btn-xs-height);
    --lf-btn-min-width: var(--lf-btn-xs-min-width);
    --lf-btn-vertical-padding: var(--lf-btn-xs-vertical-padding);
    --lf-btn-horizontal-padding: var(--lf-btn-xs-horizontal-padding);
    --lf-btn-gap: var(--lf-btn-xs-gap);
    --lf-btn-border-radius: var(--lf-btn-xs-border-radius);
    --lf-btn-icon-size: var(--lf-btn-xs-icon-size);
    --lf-btn-font: var(--lf-btn-xs-font);
  }

  .lf-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: var(--lf-btn-height);
    min-width: var(--lf-btn-min-width);
    padding: var(--lf-btn-vertical-padding) var(--lf-btn-horizontal-padding);
    gap: var(--lf-btn-gap);
    border-radius: var(--lf-btn-border-radius);
    font: var(--lf-btn-font);
    border: none;
    box-shadow: none;
    outline: none;
    transition-duration: var(--lf-transition-duration);
    transition-property: color, background-color, box-shadow;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &[disabled] {
      cursor: not-allowed;
    }
    &.icon-only, // @deprecated: use class with prefix
    &.lf-btn_icon-only {
      width: var(--lf-btn-height);
      min-width: auto;
      padding: var(--lf-btn-vertical-padding);
    }
    &.compact, // @deprecated: use class with prefix
    &.lf-btn_compact {
      min-width: auto;
    }

    .lf-icon {
      font-size: var(--lf-btn-icon-size);
    }

    // default
    &:not(.primary):not(.ghost):not(.lf-btn_primary):not(.lf-btn_ghost),
    &.secondary, // @deprecated: use class with prefix
    &.lf-btn_secondary {
      color: var(--lf-current-accent-500, var(--lf-main-900));
      background-color: var(--lf-current-accent-100, var(--lf-main-100));

      &:not([disabled]) {
        &:hover, &:focus-visible {
          color: var(--lf-current-accent-text, var(--lf-text-100));
          background-color: var(--lf-current-accent-500, var(--lf-main-900));
        }
        &:focus-visible {
          box-shadow: inset 0 0 0 2px var(--lf-current-accent-text, var(--lf-text-100)),
                      0 0 0 3px var(--lf-current-accent-500, var(--lf-main-900));
        }
        &:active {
          box-shadow: var(--lf-elevation-300);
        }
      }
      &[disabled] {
        color: var(--lf-current-accent-200, var(--lf-main-500));
        background-color: var(--lf-current-accent-100, var(--lf-main-100));
      }
    }

    &.primary, // @deprecated: use class with prefix
    &.lf-btn_primary {
      color: var(--lf-current-accent-text, var(--lf-text-100));
      background-color: var(--lf-current-accent-500, var(--lf-main-900));

      &:not([disabled]) {
        &:hover, &:focus-visible {
          background-color: var(--lf-current-accent-400, var(--lf-main-700));
        }
        &:focus-visible {
          box-shadow: inset 0 0 0 2px var(--lf-current-accent-text, var(--lf-text-100)),
                      0 0 0 3px var(--lf-current-accent-400, var(--lf-main-700));
        }
        &:active {
          box-shadow: var(--lf-elevation-300);
        }
      }
      &[disabled] {
        color: var(--lf-current-accent-200, var(--lf-main-500));
        background-color: var(--lf-current-accent-100, var(--lf-main-100));
      }
    }

    &.ghost, // @deprecated: use class with prefix
    &.lf-btn_ghost {
      color: var(--lf-current-accent-500, var(--lf-main-900));
      background-color: transparent;

      &:not([disabled]) {
        &:hover, &:focus-visible {
          color: var(--lf-current-accent-500, var(--lf-main-900));
          background-color: var(--lf-current-accent-100, var(--lf-main-100));
        }
        &:focus-visible {
          box-shadow: 0 0 0 3px var(--lf-current-accent-300, var(--lf-main-500));
        }
        &:active {
          box-shadow: var(--lf-elevation-300);
        }
      }
      &[disabled] {
        color: var(--lf-current-accent-200, var(--lf-main-500));
      }
    }
  }

  .lf-btn-block, // @deprecated: use blass as modifier
  .lf-btn_block {
    display: flex;
    width: 100%;
  }

  .btn-group, // replace all usage of btn-group to lf-btn-group and remove class without prefix
  .lf-btn-group {
    display: flex;
    gap: var(--lf-btn-group-gap);
  }
}
