@mixin for-root {
  :root {
    --lf-form-item-vertical-spacing: 32px;
  }

  // use this class for regular cases. last item will not have margin-bottom
  .lf-form-item {
    &:not(:last-child) {
      margin-bottom: var(--lf-form-item-vertical-spacing);
    }
  }

  // use this class when you don't need regular logic with `:not(:last-child)`
  .lf-form-item-with-spacing {
    margin-bottom: var(--lf-form-item-vertical-spacing);
  }
}
