@use "base";
@use "reset";
@use "typography";
@use "theming/colors";
@use "theming/elevation";
@use "layout";
@use "helpers";
@use "animations";
@use "components/index" as components;

@forward "helpers" as helpers-*;
@forward "layout" as layout-*;
@forward "theming/mixins";
@forward "mixins";

@mixin core {
  @include base.for-root();
  @include reset.for-root();
  @include colors.for-root();
  @include elevation.for-root();
  @include layout.for-root();
  @include helpers.for-root();
  @include animations.for-root();
}

@mixin typography {
  @include typography.for-root();
}

@mixin components {
  @import "@angular/cdk/overlay-prebuilt.css";
  @include components.for-root();
}

@mixin all {
  @include core();
  @include typography();
  @include components();
}
