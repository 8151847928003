@mixin for-root {
  :root {
    --lf-toggle-s-width: 36px;
    --lf-toggle-s-height: 20px;
    --lf-toggle-s-padding: 2px;
    --lf-toggle-s-indicator-icon-size: 10px;
    --lf-toggle-s-label-font: var(--lf-subtitle-m-font);
    --lf-toggle-s-gap: 10px;
    --lf-toggle-s-group-vertical-gap: 16px;
    --lf-toggle-s-group-horizontal-gap: 28px;

    --lf-toggle-m-width: 44px;
    --lf-toggle-m-height: 24px;
    --lf-toggle-m-padding: 3px;
    --lf-toggle-m-indicator-icon-size: 12px;
    --lf-toggle-m-label-font: var(--lf-subtitle-font);
    --lf-toggle-m-gap: 12px;
    --lf-toggle-m-group-vertical-gap: 18px;
    --lf-toggle-m-group-horizontal-gap: 32px;

    --lf-toggle-l-width: 56px;
    --lf-toggle-l-height: 30px;
    --lf-toggle-l-padding: 3px;
    --lf-toggle-l-indicator-icon-size: 14px;
    --lf-toggle-l-label-font: var(--lf-subtitle-l-font);
    --lf-toggle-l-gap: 16px;
    --lf-toggle-l-group-vertical-gap: 20px;
    --lf-toggle-l-group-horizontal-gap: 40px;
  }

  .lf-xl, .lf-l {
    --lf-toggle-width: var(--lf-toggle-l-width);
    --lf-toggle-height: var(--lf-toggle-l-height);
    --lf-toggle-padding: var(--lf-toggle-l-padding);
    --lf-toggle-indicator-icon-size: var(--lf-toggle-l-indicator-icon-size);
    --lf-toggle-label-font: var(--lf-toggle-l-label-font);
    --lf-toggle-gap: var(--lf-toggle-l-gap);
    --lf-toggle-group-vertical-gap: var(--lf-toggle-l-group-vertical-gap);
    --lf-toggle-group-horizontal-gap: var(--lf-toggle-l-group-horizontal-gap);
  }

  // default radio size is m
  .lf-m, :root {
    --lf-toggle-width: var(--lf-toggle-m-width);
    --lf-toggle-height: var(--lf-toggle-m-height);
    --lf-toggle-padding: var(--lf-toggle-m-padding);
    --lf-toggle-indicator-icon-size: var(--lf-toggle-m-indicator-icon-size);
    --lf-toggle-label-font: var(--lf-toggle-m-label-font);
    --lf-toggle-gap: var(--lf-toggle-m-gap);
    --lf-toggle-group-vertical-gap: var(--lf-toggle-m-group-vertical-gap);
    --lf-toggle-group-horizontal-gap: var(--lf-toggle-m-group-horizontal-gap);
  }

  .lf-s, .lf-xs {
    --lf-toggle-width: var(--lf-toggle-s-width);
    --lf-toggle-height: var(--lf-toggle-s-height);
    --lf-toggle-padding: var(--lf-toggle-s-padding);
    --lf-toggle-indicator-icon-size: var(--lf-toggle-s-indicator-icon-size);
    --lf-toggle-label-font: var(--lf-toggle-s-label-font);
    --lf-toggle-gap: var(--lf-toggle-s-gap);
    --lf-toggle-group-vertical-gap: var(--lf-toggle-s-group-vertical-gap);
    --lf-toggle-group-horizontal-gap: var(--lf-toggle-s-group-horizontal-gap);
  }
}
