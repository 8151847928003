@use 'sass:meta';
@use 'mixins';

$accents: (
  'brand': (
    text: '255, 255, 255',
    500: '57, 46, 201',
    400: '108, 99, 232',
    300: '153, 148, 227',
    200: '204, 201, 248',
    100: '235, 234, 254',
  ),
  'success': (
    text: '255, 255, 255',
    500: '61, 204, 54',
    400: '109, 217, 104',
    300: '158, 229, 154',
    200: '201, 241, 199',
    100: '232, 249, 231',
  ),
  'info': (
    text: '255, 255, 255',
    500: '26, 110, 237',
    400: '83, 146, 241',
    300: '140, 182, 246',
    200: '184, 210, 249',
    100: '221, 233, 252',
  ),
  'warning': (
    text: '255, 255, 255',
    500: '255, 168, 0',
    400: '255, 190, 64',
    300: '255, 212, 128',
    200: '255, 231, 184',
    100: '255, 242, 217',
  ),
  'attention': (
    text: '255, 255, 255',
    500: '255, 78, 22',
    400: '255, 122, 80',
    300: '255, 166, 138',
    200: '255, 214, 201',
    100: '255, 237, 232',
  ),
  'danger': (
    text: '255, 255, 255',
    500: '255, 61, 71',
    400: '255, 109, 117',
    300: '255, 158, 163',
    200: '255, 201, 204',
    100: '255, 232, 233',
  ),
);

$main: (
  100: #F3F3F4,
  300: #E7E7EA,
  500: #CED0D5,
  700: #B6B8BF,
  900: #858995,
);

$bg: (
  100: #ffffff,
  200: #F8F9FD,
  300: #F3F3F4,
  900: #191A1B,
);

$text: (
  100: #ffffff,
  300: #858995,
  500: #545A6B,
  900: #191A1B,
);

@mixin for-root () {
  :root {
    @each $accent in map-keys($accents) {
      $currentAccent: map-get($accents, $accent);
      @each $tone in map-keys($currentAccent) {
        $colorRGB: map-get($currentAccent, $tone);
        --lf-#{$accent}-#{$tone}-rgb: #{$colorRGB};
        --lf-#{$accent}-#{$tone}: rgb(var(--lf-#{$accent}-#{$tone}-rgb));
      }
      --lf-#{$accent}-rgb: var(--lf-#{$accent}-500-rgb); // default
      --lf-#{$accent}: rgb(var(--lf-#{$accent}-rgb)); // default
    }

    @each $tone in map-keys($main) {
      $color: map-get($main, $tone);
      --lf-main-#{$tone}: #{$color};
    }

    @each $tone in map-keys($bg) {
      $color: map-get($bg, $tone);
      --lf-bg-#{$tone}: #{$color};
    }
    --lf-bg: var(--lf-bg-100); // default

    @each $tone in map-keys($text) {
      $color: map-get($text, $tone);
      --lf-text-#{$tone}: #{$color};
    }
    --lf-text: var(--lf-text-900); // default
  }

  .lf-brand {
    @include mixins.accent('brand');
  }
  .lf-success {
    @include mixins.accent('success');
  }
  .lf-info {
    @include mixins.accent('info');
  }
  .lf-warning {
    @include mixins.accent('warning');
  }
  .lf-attention {
    @include mixins.accent('attention');
  }
  .lf-danger {
    @include mixins.accent('danger');
  }

  .lf-text-100 {
    color: var(--lf-text-100) !important;
  }
  .lf-text-300 {
    color: var(--lf-text-300) !important;
  }
  .lf-text-500 {
    color: var(--lf-text-500) !important;
  }
  .lf-text-900 {
    color: var(--lf-text-900) !important;
  }

  .lf-text-accent-100 {
    color: var(--lf-current-accent-100) !important;
  }
  .lf-text-accent-200 {
    color: var(--lf-current-accent-200) !important;
  }
  .lf-text-accent-300 {
    color: var(--lf-current-accent-300) !important;
  }
  .lf-text-accent-400 {
    color: var(--lf-current-accent-400) !important;
  }
  .lf-text-accent-500 {
    color: var(--lf-current-accent-500) !important;
  }
  .lf-text-accent-text {
    color: var(--lf-current-accent-text) !important;
  }
  .lf-text-accent {
    color: var(--lf-current-accent) !important;
  }

  .lf-bg-100 {
    background-color: var(--lf-bg-100) !important;
  }
  .lf-bg-200 {
    background-color: var(--lf-bg-200) !important;
  }
  .lf-bg-300 {
    background-color: var(--lf-bg-300) !important;
  }
  .lf-bg-900 {
    background-color: var(--lf-bg-900) !important;
  }
}
