// helpers for all alignment properties
@mixin for-root {
  .lf-justify-start {
    justify-content: start !important;
  }
  .lf-justify-center {
    justify-content: center !important;
  }
  .lf-justify-end {
    justify-content: end !important;
  }
  .lf-justify-between {
    justify-content: space-between !important;
  }
  .lf-justify-around {
    justify-content: space-around !important;
  }
  .lf-justify-evenly {
    justify-content: space-evenly !important;
  }

  .lf-align-start {
    align-items: start !important;
  }
  .lf-align-center {
    align-items: center !important;
  }
  .lf-align-end {
    align-items: end !important;
  }
  .lf-align-baseline {
    align-items: baseline !important;
  }
  .lf-align-stretch {
    align-items: stretch !important;
  }

  .lf-align-self-start {
    align-self: start !important;
  }
  .lf-align-self-center {
    align-self: center !important;
  }
  .lf-align-self-end {
    align-self: end !important;
  }
  .lf-align-self-baseline {
    align-self: baseline !important;
  }
  .lf-align-self-stretch {
    align-self: stretch !important;
  }

  .lf-text-left {
    text-align: left !important;
  }
  .lf-text-center {
    text-align: center !important;
  }
  .lf-text-right {
    text-align: right !important;
  }
}
