@mixin for-root {
  .lf-text-capitalize {
    text-transform: capitalize !important;
  }
  .lf-text-uppercase {
    text-transform: uppercase !important;
  }
  .lf-text-lowercase {
    text-transform: lowercase !important;
  }

  .lf-nowrap {
    white-space: nowrap;
  }

  .lf-text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
