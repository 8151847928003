@mixin for-root {
  :root {
    --lf-z-index-loader: 1000;
    --lf-z-index-toasts: 5000;
  }

  .hr {
    margin: 0;
    border: none;
    border-bottom: 1px solid var(--lf-main-100);
  }
}
