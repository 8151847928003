@mixin for-root {
  :root {
    --lf-accordion-horizontal-padding: 16px;
    --lf-accordion-toggle-icon-size: 18px;

    --lf-accordion-item-border-width: 1px;

    --lf-accordion-item-header-font: var(--lf-subtitle-font);
    --lf-accordion-item-header-min-height: 44px;
    --lf-accordion-item-header-vertical-padding: 6px;
    --lf-accordion-item-header-gap: 16px;

    --lf-accordion-item-content-vertical-padding: 16px;
  }

  .lf-accordion {
    display: block;
  }
  .lf-accordion__items-wrapper {}

  .lf-accordion-item {
    display: block;
    background: var(--lf-bg-100);
    border-bottom: var(--lf-accordion-item-border-width) solid var(--lf-main-300);
    transition-duration: var(--lf-transition-duration);
    transition-property: background-color;
    overflow: hidden;

    &:hover:not(.lf-accordion-item_disabled),
    &:focus-visible:not(.lf-accordion-item_disabled),
    &.lf-accordion-item_opened {
      background: var(--lf-bg-200);
    }
  }
  .lf-accordion-item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--lf-accordion-item-header-gap);
    min-height: var(--lf-accordion-item-header-min-height);
    padding: var(--lf-accordion-item-header-vertical-padding) var(--lf-accordion-horizontal-padding);
    cursor: pointer;
  }
  .lf-accordion-item__header-title {
    font: var(--lf-accordion-item-header-font);
    color: var(--lf-text-500);
    flex: 1 1 auto;
  }
  .lf-accordion-item__content {}
  .lf-accordion-item__content-body {
    padding: var(--lf-accordion-item-content-vertical-padding) var(--lf-accordion-horizontal-padding);
  }
  .lf-accordion-item__toggle-icon {
    font-size: var(--lf-accordion-toggle-icon-size);
    color: var(--lf-brand-500);
  }

  .lf-accordion-item_disabled {
    & > .lf-accordion-item__header {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
