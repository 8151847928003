// helpers for layout options
@mixin for-root {
  .lf-full-height {
    height: 100% !important;
  }

  .lf-flex {
    display: flex !important;
  }

  .lf-flex-row {
    flex-direction: row !important;
  }
  .lf-flex-column {
    flex-direction: column !important;
  }
  .lf-vertical-flex {
    display: flex !important;
    flex-direction: column !important;
  }

  .lf-flex-item-fixed {
    flex: 0 0 auto !important;
    min-width: 0;
  }
  .lf-flex-item-fluid,
  .lf-flex-spacer {
    flex: 1 1 auto !important;
    min-width: 0;
  }

  .lf-flex-wrap {
    flex-wrap: wrap;
  }
  .lf-flex-nowrap {
    flex-wrap: nowrap;
  }

  .lf-overflow-auto {
    overflow: auto !important;
  }
  .lf-overflow-x-auto {
    overflow-x: auto !important;
  }
  .lf-overflow-y-auto {
    overflow-y: auto !important;
  }
  .lf-overflow-hidden {
    overflow: hidden !important;
  }
  .lf-overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .lf-overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .lf-overflow-scroll {
    overflow: scroll !important;
  }
  .lf-overflow-x-scroll {
    overflow-x: scroll !important;
  }
  .lf-overflow-y-scroll {
    overflow-y: scroll !important;
  }
  .lf-overflow-visible {
    overflow: visible !important;
  }
  .lf-overflow-x-visible {
    overflow-x: visible !important;
  }
  .lf-overflow-y-visible {
    overflow-y: visible !important;
  }

  .lf-width-10 {
    width: 10% !important;
  }
  .lf-width-20 {
    width: 20% !important;
  }
  .lf-width-25 {
    width: 25% !important;
  }
  .lf-width-30 {
    width: 30% !important;
  }
  .lf-width-40 {
    width: 40% !important;
  }
  .lf-width-50 {
    width: 50% !important;
  }
  .lf-width-60 {
    width: 60% !important;
  }
  .lf-width-70 {
    width: 70% !important;
  }
  .lf-width-75 {
    width: 75% !important;
  }
  .lf-width-80 {
    width: 80% !important;
  }
  .lf-width-90 {
    width: 90% !important;
  }
  .lf-width-100 {
    width: 100% !important;
  }

  .lf-gap-gutter {
    gap: var(--lf-page-gutter-size) !important;
  }

  .lf-gap-half-gutter {
    gap: calc(var(--lf-page-gutter-size) / 2) !important;
  }

  .lf-relative {
    position: relative !important;
  }

  .lf-absolute {
    position: absolute !important;
  }

  .lf-sticky {
    position: sticky !important;
  }

  .lf-top-0 {
    top: 0;
  }
  .lf-left-0 {
    left: 0;
  }
  .lf-right-0 {
    right: 0;
  }
  .lf-bottom-0 {
    bottom: 0;
  }
}
