@mixin for-root {
  :root {
    --lf-tab-list-gap: 10px;

    --lf-tab-height: 45px;
    --lf-tab-min-width: 80px;
    --lf-tab-padding-horizontal: 8px;
    --lf-tab-padding-vertical: 0;
    --lf-tab-icon-size: 16px;
    --lf-tab-active-indicator-width: 2px;
    --lf-tab-gap: 8px;
    --lf-tab-text-font: var(--lf-subtitle-m-font);
  }
}
