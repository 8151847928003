@mixin for-root {
  :root {
    --lf-badge-vertical-padding: 5px;
    --lf-badge-horizontal-padding: 10px;
    --lf-badge-border-radius: 40px;
    --lf-badge-font: var(--lf-subtitle-m-font);
    --lf-badge-gap: 5px;

    --lf-badge-group-gap: 8px;

    // Indicator Badge
    --lf-indicator-badge-xl-vertical-padding: 6px;
    --lf-indicator-badge-xl-horizontal-padding: 8px;
    --lf-indicator-badge-xl-border-radius: 100px;
    --lf-indicator-badge-xl-font: var(--lf-caption-font);
    --lf-indicator-badge-xl-size-with-content: 24px;
    --lf-indicator-badge-xl-size-without-content: 20px;
    --lf-indicator-badge-xl-offset-with-content: -7px;
    --lf-indicator-badge-xl-offset-without-content: -5px;

    --lf-indicator-badge-l-vertical-padding: 5px;
    --lf-indicator-badge-l-horizontal-padding: 7px;
    --lf-indicator-badge-l-border-radius: 100px;
    --lf-indicator-badge-l-font: var(--lf-caption-font);
    --lf-indicator-badge-l-size-with-content: 22px;
    --lf-indicator-badge-l-size-without-content: 16px;
    --lf-indicator-badge-l-offset-with-content: -7px;
    --lf-indicator-badge-l-offset-without-content: -4px;

    --lf-indicator-badge-m-vertical-padding: 4px;
    --lf-indicator-badge-m-horizontal-padding: 6px;
    --lf-indicator-badge-m-border-radius: 100px;
    --lf-indicator-badge-m-font: var(--lf-caption-font);
    --lf-indicator-badge-m-size-with-content: 20px;
    --lf-indicator-badge-m-size-without-content: 14px;
    --lf-indicator-badge-m-offset-with-content: -6px;
    --lf-indicator-badge-m-offset-without-content: -4px;

    --lf-indicator-badge-s-vertical-padding: 3px;
    --lf-indicator-badge-s-horizontal-padding: 4px;
    --lf-indicator-badge-s-border-radius: 100px;
    --lf-indicator-badge-s-font: var(--lf-caption-s-font);
    --lf-indicator-badge-s-size-with-content: 16px;
    --lf-indicator-badge-s-size-without-content: 12px;
    --lf-indicator-badge-s-offset-with-content: -5px;
    --lf-indicator-badge-s-offset-without-content: -3px;

    --lf-indicator-badge-xs-vertical-padding: 2px;
    --lf-indicator-badge-xs-horizontal-padding: 3px;
    --lf-indicator-badge-xs-border-radius: 100px;
    --lf-indicator-badge-xs-font: var(--lf-caption-s-font);
    --lf-indicator-badge-xs-size-with-content: 14px;
    --lf-indicator-badge-xs-size-without-content: 10px;
    --lf-indicator-badge-xs-offset-with-content: -5px;
    --lf-indicator-badge-xs-offset-without-content: -3px;
  }

  .lf-xl {
    --lf-indicator-badge-vertical-padding: var(--lf-indicator-badge-xl-vertical-padding);
    --lf-indicator-badge-horizontal-padding: var(--lf-indicator-badge-xl-horizontal-padding);
    --lf-indicator-badge-border-radius: var(--lf-indicator-badge-xl-border-radius);
    --lf-indicator-badge-font: var(--lf-indicator-badge-xl-font);
    --lf-indicator-badge-size-with-content: var(--lf-indicator-badge-xl-size-with-content);
    --lf-indicator-badge-size-without-content: var(--lf-indicator-badge-xl-size-without-content);
    --lf-indicator-badge-offset-with-content: var(--lf-indicator-badge-xl-offset-with-content);
    --lf-indicator-badge-offset-without-content: var(--lf-indicator-badge-xl-offset-without-content);
  }

  .lf-l {
    --lf-indicator-badge-vertical-padding: var(--lf-indicator-badge-l-vertical-padding);
    --lf-indicator-badge-horizontal-padding: var(--lf-indicator-badge-l-horizontal-padding);
    --lf-indicator-badge-border-radius: var(--lf-indicator-badge-l-border-radius);
    --lf-indicator-badge-font: var(--lf-indicator-badge-l-font);
    --lf-indicator-badge-size-with-content: var(--lf-indicator-badge-l-size-with-content);
    --lf-indicator-badge-size-without-content: var(--lf-indicator-badge-l-size-without-content);
    --lf-indicator-badge-offset-with-content: var(--lf-indicator-badge-l-offset-with-content);
    --lf-indicator-badge-offset-without-content: var(--lf-indicator-badge-l-offset-without-content);
  }

  :root, .lf-m {
    --lf-indicator-badge-vertical-padding: var(--lf-indicator-badge-m-vertical-padding);
    --lf-indicator-badge-horizontal-padding: var(--lf-indicator-badge-m-horizontal-padding);
    --lf-indicator-badge-border-radius: var(--lf-indicator-badge-m-border-radius);
    --lf-indicator-badge-font: var(--lf-indicator-badge-m-font);
    --lf-indicator-badge-size-with-content: var(--lf-indicator-badge-m-size-with-content);
    --lf-indicator-badge-size-without-content: var(--lf-indicator-badge-m-size-without-content);
    --lf-indicator-badge-offset-with-content: var(--lf-indicator-badge-m-offset-with-content);
    --lf-indicator-badge-offset-without-content: var(--lf-indicator-badge-m-offset-without-content);
  }

  .lf-s {
    --lf-indicator-badge-vertical-padding: var(--lf-indicator-badge-s-vertical-padding);
    --lf-indicator-badge-horizontal-padding: var(--lf-indicator-badge-s-horizontal-padding);
    --lf-indicator-badge-border-radius: var(--lf-indicator-badge-s-border-radius);
    --lf-indicator-badge-font: var(--lf-indicator-badge-s-font);
    --lf-indicator-badge-size-with-content: var(--lf-indicator-badge-s-size-with-content);
    --lf-indicator-badge-size-without-content: var(--lf-indicator-badge-s-size-without-content);
    --lf-indicator-badge-offset-with-content: var(--lf-indicator-badge-s-offset-with-content);
    --lf-indicator-badge-offset-without-content: var(--lf-indicator-badge-s-offset-without-content);
  }

  .lf-xs {
    --lf-indicator-badge-vertical-padding: var(--lf-indicator-badge-xs-vertical-padding);
    --lf-indicator-badge-horizontal-padding: var(--lf-indicator-badge-xs-horizontal-padding);
    --lf-indicator-badge-border-radius: var(--lf-indicator-badge-xs-border-radius);
    --lf-indicator-badge-font: var(--lf-indicator-badge-xs-font);
    --lf-indicator-badge-size-with-content: var(--lf-indicator-badge-xs-size-with-content);
    --lf-indicator-badge-size-without-content: var(--lf-indicator-badge-xs-size-without-content);
    --lf-indicator-badge-offset-with-content: var(--lf-indicator-badge-xs-offset-with-content);
    --lf-indicator-badge-offset-without-content: var(--lf-indicator-badge-xs-offset-without-content);
  }

  .lf-badge {
    display: inline-flex;
    align-items: center;
    gap: var(--lf-badge-gap);
    padding: var(--lf-badge-vertical-padding) var(--lf-badge-horizontal-padding);
    border-radius: var(--lf-badge-border-radius);
    font: var(--lf-badge-font);
    transition-duration: var(--lf-transition-duration);
    transition-property: color, background-color;
    color: var(--lf-current-accent-500, var(--lf-main-900));
    background-color: var(--lf-current-accent-100, var(--lf-main-100));
  }

  .lf-badges-group {
    display: flex;
    gap: var(--lf-badge-group-gap);

    &.lf-badges-group_horizontal {
      flex-wrap: wrap;
    }
    &.lf-badges-group_vertical {
      flex-direction: column;
    }
  }

  .lf-indicator-badge {
    display: block;
    position: relative;
    padding: var(--lf-indicator-badge-vertical-padding) var(--lf-indicator-badge-horizontal-padding);
    border-radius: var(--lf-indicator-badge-border-radius);

    background-color: var(--lf-current-accent-500);
    color: var(--lf-current-accent-text);

    font: var(--lf-indicator-badge-font);
    line-height: 1;
    height: 1em;
    text-align: center;

    transition-duration: var(--lf-transition-duration);
    transition-property: color, background-color;

    min-width: var(--lf-indicator-badge-size-with-content);
    min-height: var(--lf-indicator-badge-size-with-content);
    &:empty {
      min-width: var(--lf-indicator-badge-size-without-content);
      min-height: var(--lf-indicator-badge-size-without-content);
    }

    &.lf-indicator-badge_pinned {
      position: absolute;
      max-width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;

      top: var(--lf-indicator-badge-offset-with-content);
      right: var(--lf-indicator-badge-offset-with-content);
      &:empty {
        top: var(--lf-indicator-badge-offset-without-content);
        right: var(--lf-indicator-badge-offset-without-content);
      }
    }

    & > .lf-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: calc(2px + var(--lf-indicator-badge-size-with-content) - 2 * var(--lf-indicator-badge-vertical-padding));
    }
  }

  .lf-btn {
    &:not([disabled]) {
      &.lf-btn_primary,
      &.lf-btn_secondary:hover,
      &.lf-btn_secondary:focus-visible {
        .lf-indicator-badge.lf-indicator-badge_unpinned {
          background-color: var(--lf-current-accent-100);
          color: var(--lf-current-accent-500);
        }
      }
    }
    &[disabled] {
      .lf-indicator-badge.lf-indicator-badge_unpinned {
        background-color: var(--lf-current-accent-200);
        color: var(--lf-current-accent-100);
      }
    }
  }

}
