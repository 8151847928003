@mixin for-root () {
  :root {
    --lf-select-option-height: 42px;
    --lf-select-option-divider-width: 1px;

    --lf-select-option-padding-vertical: 4px;
    --lf-select-option-padding-horizontal: 14px;

    --lf-select-option-gap: 12px;

    --lf-select-option-font: var(--lf-subtitle-m-font);
    --lf-select-option-font-secondary: var(--lf-text-m-font);
    --lf-select-option-lines-gap: 3px;

    --lf-select-option-icon-size: 20px;
  }
}
