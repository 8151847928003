@use '../mixins';

@mixin for-root () {
  .lf-icon {
    display: inline-flex;
  }
  button.lf-icon {
    @include mixins.reset-button-styles();
    font-size: inherit;
    color: var(--lf-current-accent-500, var(--lf-main-900));
    transition-duration: var(--lf-transition-duration);
    transition-property: color;

    &:hover, &:focus-visible {
      color: var(--lf-current-accent-400, var(--lf-main-700));
    }
  }
  .lf-icon_inline {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }
}
