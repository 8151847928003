@mixin for-root () {
  :root {
    --lf-overlay-animation-initial-scale: 0.95;
    --lf-overlay-animation-initial-offset: 15px;
  }

  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .40);
  }

  .lf-overlay-content_animated,
  .lf-overlay-content_animated-left,
  .lf-overlay-content_animated-right,
  .lf-overlay-content_animated-up,
  .lf-overlay-content_animated-down,
  .lf-overlay-content_animated-slide-in-left,
  .lf-overlay-content_animated-slide-from-left,
  .lf-overlay-content_animated-slide-in-right,
  .lf-overlay-content_animated-slide-from-right {
    animation-iteration-count: 1;
    animation-duration: var(--lf-transition-duration-fast);
    animation-timing-function: ease-out;
  }
  .lf-overlay-content_animated {
    animation-name: fade-scale;
  }
  .lf-overlay-content_animated-left {
    animation-name: fade-scale-left;
  }
  .lf-overlay-content_animated-right {
    animation-name: fade-scale-right;
  }
  .lf-overlay-content_animated-up {
    animation-name: fade-scale-up;
  }
  .lf-overlay-content_animated-down {
    animation-name: fade-scale-down;
  }

  .lf-overlay-content_animated-slide-in-left,
  .lf-overlay-content_animated-slide-from-right {
    animation-name: slide-in-left;
  }
  .lf-overlay-content_animated-slide-in-right,
  .lf-overlay-content_animated-slide-from-left {
    animation-name: slide-in-right;
  }

  .lf-overlay-content_lock-animation {
    animation: none;
  }
}

@keyframes fade-scale {
  from {
    opacity: 0;
    transform: scale(var(--lf-overlay-animation-initial-scale));
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-scale-down {
  from {
    opacity: 0;
    transform: scale(var(--lf-overlay-animation-initial-scale)) translateY(calc(-1 * var(--lf-overlay-animation-initial-offset)));
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fade-scale-right {
  from {
    opacity: 0;
    transform: scale(var(--lf-overlay-animation-initial-scale)) translateX(calc(-1 * var(--lf-overlay-animation-initial-offset)));
  }
  to {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}
@keyframes fade-scale-left {
  from {
    opacity: 0;
    transform: scale(var(--lf-overlay-animation-initial-scale)) translateX(var(--lf-overlay-animation-initial-offset));
  }
  to {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}
@keyframes fade-scale-up {
  from {
    opacity: 0;
    transform: scale(var(--lf-overlay-animation-initial-scale)) translateY(var(--lf-overlay-animation-initial-offset));
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-in-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
