@mixin for-root {
  :root {
    --lf-table-header-height: 36px;
    --lf-table-header-font: var(--lf-caption-font);
    --lf-table-header-sortable-icon-size: 24px;

    --lf-table-side-gap: 8px;
    --lf-table-side-gap-md: 14px;
    --lf-table-side-gap-lg: 20px;

    --lf-table-cell-height: 49px;
    --lf-table-cell-padding-vertical: 4px;
  }
}
