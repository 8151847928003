@mixin for-root {
  :root {
    --lf-list-border-radius: 6px;
    --lf-list-border-width: 1px;

    --lf-list-item-height: 52px;
    --lf-list-item-padding-horizontal: 16px;
    --lf-list-item-padding-vertical: 8px;
    --lf-list-item-gap: 12px;
    --lf-list-item-border-width: 1px;
    --lf-list-item-text-lines-gap: 4px;
    --lf-list-item-font: var(--lf-subtitle-m-font);
    --lf-list-item-font-secondary: var(--lf-text-m-font);

    --lf-list-item-icon-size: 20px;

    --lf-list-sticky-border-width: 1px;
    --lf-list-sticky-padding-horizontal: var(--lf-list-item-padding-horizontal);
    --lf-list-sticky-padding-vertical: 5px;
    --lf-list-sticky-font: var(--lf-subtitle-s-font);
  }
}
