@use '../../mixins';

@mixin for-root {
  :root {
    // m size
    --lf-field-m-height: 38px;
    --lf-field-m-font: var(--lf-text-m-font);
    --lf-field-m-icon-size: 16px;
    --lf-field-m-padding-vertical: 0;
    --lf-field-m-padding-horizontal: 10px;
    --lf-field-m-gap: 8px;
    --lf-field-m-border-width: 2px;
    --lf-field-m-border-radius: 6px;

    --lf-field-m-label-font: var(--lf-caption-font);
    --lf-field-m-label-spacing: 10px;

    --lf-field-m-addon-font: var(--lf-subtitle-s-font);
    --lf-field-m-addon-min-width: 56px;
    --lf-field-m-addon-padding: 8px 10px;

    --lf-field-m-hint-font: var(--lf-text-s-font);
    --lf-field-m-hint-spacing: 4px;

    // l size
    --lf-field-l-height: 44px;
    --lf-field-l-font: var(--lf-text-font);
    --lf-field-l-icon-size: 20px;
    --lf-field-l-padding-vertical: 0;
    --lf-field-l-padding-horizontal: 12px;
    --lf-field-l-gap: 8px;
    --lf-field-l-border-width: 2px;
    --lf-field-l-border-radius: 6px;

    --lf-field-l-label-font: var(--lf-caption-font);
    --lf-field-l-label-spacing: 10px;

    --lf-field-l-addon-font: var(--lf-subtitle-m-font);
    --lf-field-l-addon-min-width: 60px;
    --lf-field-l-addon-padding: 8px 12px;

    --lf-field-l-hint-font: var(--lf-text-s-font);
    --lf-field-l-hint-spacing: 4px;

    // lx size
    --lf-field-xl-height: 50px;
    --lf-field-xl-font: var(--lf-text-l-font);
    --lf-field-xl-icon-size: 24px;
    --lf-field-xl-padding-vertical: 0;
    --lf-field-xl-padding-horizontal: 16px;
    --lf-field-xl-gap: 8px;
    --lf-field-xl-border-width: 2px;
    --lf-field-xl-border-radius: 6px;

    --lf-field-xl-label-font: var(--lf-caption-font);
    --lf-field-xl-label-spacing: 10px;

    --lf-field-xl-addon-font: var(--lf-subtitle-font);
    --lf-field-xl-addon-min-width: 64px;
    --lf-field-xl-addon-padding: 8px 15px;

    --lf-field-xl-hint-font: var(--lf-text-s-font);
    --lf-field-xl-hint-spacing: 4px;
  }

  // default field size is m
  .lf-xs, .lf-s, .lf-m, :root {
    --lf-field-height: var(--lf-field-m-height);
    --lf-field-font: var(--lf-field-m-font);
    --lf-field-icon-size: var(--lf-field-m-icon-size);
    --lf-field-padding-vertical: var(--lf-field-m-padding-vertical);
    --lf-field-padding-horizontal: var(--lf-field-m-padding-horizontal);
    --lf-field-gap: var(--lf-field-m-gap);
    --lf-field-border-width: var(--lf-field-m-border-width);
    --lf-field-border-radius: var(--lf-field-m-border-radius);
    --lf-field-label-font: var(--lf-field-m-label-font);
    --lf-field-label-spacing: var(--lf-field-m-label-spacing);
    --lf-field-addon-font: var(--lf-field-m-addon-font);
    --lf-field-addon-min-width: var(--lf-field-m-addon-min-width);
    --lf-field-addon-padding: var(--lf-field-m-addon-padding);
    --lf-field-hint-font: var(--lf-field-m-hint-font);
    --lf-field-hint-spacing: var(--lf-field-m-hint-spacing);
  }

  .lf-l {
    --lf-field-height: var(--lf-field-l-height);
    --lf-field-font: var(--lf-field-l-font);
    --lf-field-icon-size: var(--lf-field-l-icon-size);
    --lf-field-padding-vertical: var(--lf-field-l-padding-vertical);
    --lf-field-padding-horizontal: var(--lf-field-l-padding-horizontal);
    --lf-field-gap: var(--lf-field-l-gap);
    --lf-field-border-width: var(--lf-field-l-border-width);
    --lf-field-border-radius: var(--lf-field-l-border-radius);
    --lf-field-label-font: var(--lf-field-l-label-font);
    --lf-field-label-spacing: var(--lf-field-l-label-spacing);
    --lf-field-addon-font: var(--lf-field-l-addon-font);
    --lf-field-addon-min-width: var(--lf-field-l-addon-min-width);
    --lf-field-addon-padding: var(--lf-field-l-addon-padding);
    --lf-field-hint-font: var(--lf-field-l-hint-font);
    --lf-field-hint-spacing: var(--lf-field-l-hint-spacing);
  }

  .lf-xl {
    --lf-field-height: var(--lf-field-xl-height);
    --lf-field-font: var(--lf-field-xl-font);
    --lf-field-icon-size: var(--lf-field-xl-icon-size);
    --lf-field-padding-vertical: var(--lf-field-xl-padding-vertical);
    --lf-field-padding-horizontal: var(--lf-field-xl-padding-horizontal);
    --lf-field-gap: var(--lf-field-xl-gap);
    --lf-field-border-width: var(--lf-field-xl-border-width);
    --lf-field-border-radius: var(--lf-field-xl-border-radius);
    --lf-field-label-font: var(--lf-field-xl-label-font);
    --lf-field-label-spacing: var(--lf-field-xl-label-spacing);
    --lf-field-addon-font: var(--lf-field-xl-addon-font);
    --lf-field-addon-min-width: var(--lf-field-xl-addon-min-width);
    --lf-field-addon-padding: var(--lf-field-xl-addon-padding);
    --lf-field-hint-font: var(--lf-field-xl-hint-font);
    --lf-field-hint-spacing: var(--lf-field-xl-hint-spacing);
  }

  .lf-label {
    display: block;
    font: var(--lf-field-label-font);
    margin-bottom: var(--lf-field-label-spacing);
    color: var(--lf-text-300);
  }

  .lf-field {
    display: block;
    position: relative;
    --lf-field-side-padding-with-affix: calc(var(--lf-field-padding-horizontal) + var(--lf-field-icon-size) + var(--lf-field-gap));
    --lf-field-height-without-borders: calc(var(--lf-field-height) - 2 * var(--lf-field-border-width));

    // label styles
    &.no-label .label-wrapper {
      display: none;
    }
    &.required .lf-label.show-required:after {
      content: ' *';
      color: var(--lf-danger-500);
    }

    // styles for full group (.field-wrapper + buttons)
    .group {
      display: flex;
      align-items: center;
      gap: var(--lf-field-gap);
    }

    // styles for whole wrapper & addons
    .field-wrapper {
      display: flex;
      width: 100%;
      min-height: var(--lf-field-height);
      overflow: hidden;
      border: var(--lf-field-border-width) solid var(--lf-main-300);
      border-radius: var(--lf-field-border-radius);
      transition-duration: var(--lf-transition-duration);
      transition-property: box-shadow, border-color;
    }
    .field-addon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--lf-field-addon-padding);
      flex-shrink: 0;
      min-width: var(--lf-field-addon-min-width);
      border: 0 solid var(--lf-main-300);
      font: var(--lf-field-addon-font);
      color: var(--lf-text-500);
      background-color: var(--lf-bg-300);
      transition-duration: var(--lf-transition-duration);
      transition-property: border-color;
    }
    .field-addon__content.lf-icon {
      font-size: var(--lf-field-icon-size);
    }
    .field-addon_prefix {
      border-right-width: var(--lf-field-border-width);
    }
    .field-addon_suffix {
      border-left-width: var(--lf-field-border-width);
    }
    &.no-addon-prefix {
      .field-addon_prefix {
        display: none;
      }
    }
    &.no-addon-suffix {
      .field-addon_suffix {
        display: none;
      }
    }

    // styles for control & wrapper
    .control-wrapper {
      position: relative;
      flex: 1 1;
      overflow: hidden;
    }
    .lf-control {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      padding: var(--lf-field-padding-vertical) var(--lf-field-padding-horizontal);
      font: var(--lf-field-font);
      color: var(--lf-text-900);
      background-color: var(--lf-bg-100);
      outline: none;
      transition-duration: var(--lf-transition-duration);
      transition-property: background-color, color;
      &::placeholder {
        color: var(--lf-main-900);
      }
    }
    &.with-prefix {
      .lf-control {
        padding-left: var(--lf-field-side-padding-with-affix);
      }
    }
    &.with-suffix {
      .lf-control {
        padding-right: var(--lf-field-side-padding-with-affix);
      }
    }

    .lf-input {
      &[type="file"] {
        cursor: pointer;
        overflow: hidden;
      }
      // File input buttons theming
      &::file-selector-button {
        height: 100%;
        margin-left: calc(-1 * var(--lf-field-padding-horizontal));
        margin-right: var(--lf-field-padding-horizontal);
        padding: var(--lf-field-addon-padding);
        border: 0 solid var(--lf-main-300);
        border-right-width: 2px;
        border-radius: 0;
        font: var(--lf-field-addon-font);
        color: var(--lf-text-500);
        background-color: var(--lf-bg-300);
        pointer-events: none;
      }
      // [type=date|time|datetime-local] theming
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        opacity: 0.4;
        transition-duration: var(--lf-transition-duration);
        transition-property: opacity;
        &:hover {
          opacity: 0.6;
        }
      }
    }

    .lf-textarea {
      resize: none;
      padding: var(--lf-field-padding-horizontal);
      min-height: var(--lf-field-height-without-borders);
      &.lf-textarea_resizable {
        resize: vertical;
      }
    }

    // styles for prefix & suffix
    .field-affix {
      display: flex;
      position: absolute;
      top: 0;
      height: var(--lf-field-height-without-borders);
      align-items: center;
      pointer-events: none;
    }
    .field-affix__content {
      display: flex;
      color: var(--lf-main-700);
      font-size: var(--lf-field-icon-size);
      transition-duration: var(--lf-transition-duration);
      transition-property: color;
    }
    .field-affix_prefix {
      left: var(--lf-field-padding-horizontal);
    }
    .field-affix_suffix {
      right: var(--lf-field-padding-horizontal);
    }
    button.field-affix__content {
      @include mixins.reset-button-styles();
      pointer-events: all;
      &:hover, &:focus-visible {
        color: var(--lf-main-900);
      }
    }

    // styles for hints & errors
    .hints-wrapper,
    .errors-wrapper {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      top: calc(100% + var(--lf-field-hint-spacing));
      font: var(--lf-field-hint-font);
      opacity: 1;
      transform: translateY(0);
      transition-duration: var(--lf-transition-duration);
      transition-property: transform, opacity;
    }
    .field-hint {
      color: var(--lf-main-900);
    }
    button.field-hint,
    .field-hint.field-hint_btn {
      @include mixins.reset-button-styles();
      display: flex;
      font: inherit;
      transition-property: color;
      transition-duration: var(--lf-transition-duration);
      &:not([disabled]) {
        &:hover, &:focus-visible {
          color: var(--lf-brand-500);
        }
      }
      &[disabled] {
        cursor: not-allowed;
        color: var(--lf-main-500);
      }
    }
    .errors-wrapper {
      flex-direction: column;
    }
    .field-error {
      display: block;
      color: var(--lf-danger-500);
      & + .field-error {
        display: none;
      }
    }
    &.show-all-errors {
      .field-error {
        display: block;
      }
    }
    &.no-hints .hints-wrapper,
    .errors-wrapper {
      opacity: 0;
      transform: translateY(-10px);
      pointer-events: none;
    }
    &.with-hints.with-errors.invalid {
      .hints-wrapper {
        transform: translateY(10px);
        opacity: 0;
        pointer-events: none;
      }
    }
    &.with-errors.invalid {
      .errors-wrapper {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
      }
    }
    .field-wrapper:hover {
      border-color: var(--lf-brand-400);
    }

    &.focused .field-wrapper {
      border-color: var(--lf-brand-500);
      box-shadow: var(--lf-elevation-200);
    }

    &.invalid .field-wrapper {
      border-color: var(--lf-danger-500);
    }

    &.disabled {
      .field-wrapper {
        border-color: var(--lf-main-300);
        cursor: not-allowed;
      }
      .field-addon,
      .control-wrapper {
        pointer-events: none;
      }
      .field-addon,
      .field-affix__content.lf-icon {
        color: var(--lf-main-500);
      }
      .lf-control {
        color: var(--lf-main-700);
        background-color: var(--lf-bg-300);
      }
    }
  }
}
