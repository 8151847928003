@mixin for-root () {
  :root {
    --lf-card-padding-vertical: 24px;
    --lf-card-padding-horizontal: 20px;
    --lf-card-border-radius: 10px;

    --lf-card-header-margin-bottom: 24px;
    --lf-card-header-margin: 0 0 var(--lf-card-header-margin-bottom);
    --lf-card-header-color: var(--lf-brand-500);
  }

  .lf-card {
    border-radius: var(--lf-card-border-radius);
    border: 1px solid var(--lf-main-100);
    background-color: var(--lf-bg);
    padding: var(--lf-card-padding-vertical) var(--lf-card-padding-horizontal);
  }
  .lf-card_no-paddings {
    padding: 0;
  }

  .lf-card-header {
    margin: var(--lf-card-header-margin);
    color: var(--lf-card-header-color);
  }

  .lf-card-hr {
    margin-left: calc(-1 * var(--lf-card-padding-horizontal));
    margin-right: calc(-1 * var(--lf-card-padding-horizontal));
  }
}
