@mixin for-root {
  :root {
    --lf-nav-tab-list-padding: 4px;
    --lf-nav-tab-list-border-radius: 8px;

    --lf-nav-tab-height: 30px;
    --lf-nav-tab-width: 80px;
    --lf-nav-tab-padding-horizontal: 16px;
    --lf-nav-tab-padding-vertical: 5px;
    --lf-nav-tab-gap: 4px;
    --lf-nav-tab-icon-size: 20px;
    --lf-nav-tab-text-font: var(--lf-subtitle-s-font);
    --lf-nav-tab-border-radius: 6px;
  }
}
