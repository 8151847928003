@mixin for-root {
  :root {
    --lf-chip-height: 24px;
    --lf-chip-vertical-padding: 3px;
    --lf-chip-horizontal-padding: 7px;
    --lf-chip-border-radius: 6px;
    --lf-chip-gap: 4px;

    --lf-chip-font: var(--lf-subtitle-m-font);
    --lf-chip-font-compact: var(--lf-text-m-font);

    --lf-chip-icon-size: 16px;
    --lf-chip-icon-size-compact: 14px;

    --lf-chip-grid-gap: 8px;
    --lf-chip-grid-gap-compact: 4px;
  }
}
