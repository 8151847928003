@mixin accent ($accentName) {
  --lf-current-accent-text-rgb: var(--lf-#{$accentName}-text-rgb);
  --lf-current-accent-100-rgb: var(--lf-#{$accentName}-100-rgb);
  --lf-current-accent-200-rgb: var(--lf-#{$accentName}-200-rgb);
  --lf-current-accent-300-rgb: var(--lf-#{$accentName}-300-rgb);
  --lf-current-accent-400-rgb: var(--lf-#{$accentName}-400-rgb);
  --lf-current-accent-500-rgb: var(--lf-#{$accentName}-500-rgb);
  --lf-current-accent-rgb: var(--lf-#{$accentName}-rgb);

  --lf-current-accent-text: rgb(var(--lf-current-accent-text-rgb));
  --lf-current-accent-100: rgb(var(--lf-current-accent-100-rgb));
  --lf-current-accent-200: rgb(var(--lf-current-accent-200-rgb));
  --lf-current-accent-300: rgb(var(--lf-current-accent-300-rgb));
  --lf-current-accent-400: rgb(var(--lf-current-accent-400-rgb));
  --lf-current-accent-500: rgb(var(--lf-current-accent-500-rgb));
  --lf-current-accent: rgb(var(--lf-current-accent-rgb));
}
