@use 'sass:meta';
@use 'mixins';

$elevations: (
  100: '0 2px 8px 0 rgba(25, 26, 27, 0.12)',
  100-inverse: '0 -2px 8px 0 rgba(25, 26, 27, 0.12)',

  200: '0 4px 12px 0 rgba(25, 26, 27, 0.16)',
  200-inverse: '0 -4px 12px 0 rgba(25, 26, 27, 0.16)',

  300: '0 8px 16px 0 rgba(25, 26, 27, 0.20)',
  300-inverse: '0 -8px 16px 0 rgba(25, 26, 27, 0.20)',
);

@mixin for-root {
  :root {
    @each $tone in map-keys($elevations) {
      $elevation: map-get($elevations, $tone);
      --lf-elevation-#{$tone}: #{$elevation};
    }
  }
  @each $tone in map-keys($elevations) {
    .lf-elevation-#{$tone} {
      box-shadow: var(--lf-elevation-#{$tone});
    }
  }
}
