@mixin for-root {
  :root {
    --lf-loader-spinner-size: 48px;
    --lf-loader-text-font: var(--lf-h5-font);
    --lf-loader-gap: 16px;
  }

  .lf-element-with-loader {
    position: relative;
  }

  .lf-dialog-with-loader {
    position: relative;
    border-radius: var(--lf-dialog-border-radius);
    overflow: hidden;
  }
}
